<template>
  <div class="meta-box" v-cloak>
    <div class="title-container">
      <h3 class="title">
        {{ $t('promotion.millionDollarCashPool.title') }}
      </h3>
    </div>

    <el-row :gutter="20" class="sub-box1">
      <el-col :xs="24">
        <div class="sub-title">
          {{ $t('promotion.millionDollarCashPool.desc') }}
        </div>
      </el-col>
      <el-col :xs="24" class="button-box opt-button-box">
        <el-button class="el-button btn-blue long-button" data-testid="participateNow" @click="redirectToPuprimePage">
          {{ $t('promotion.millionDollarCashPool.registerNow') }}
        </el-button>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="sub-box2">
      <!-- How to claim -->
      <el-col :xs="24">
        <Description
          :title="$t('promotion.millionDollarCashPool.claimTitle')"
          :descriptions="$t('promotion.millionDollarCashPool.claimDescriptions')"
        />
      </el-col>
      <!-- eligibility -->
      <el-col :xs="24" class="agreeTnc-box">
        <Description :title="$t('promotion.millionDollarCashPool.eligibilityTitle')" useSlotForLiTag>
          <i18n path="promotion.millionDollarCashPool.eligibleDescription1" tag="li">
            <template v-slot:page>
              <a :href="puprimePageUrl" target="_blank" data-testid="gotoPuprimePage">
                {{ $t('promotion.millionDollarCashPool.page') }}
              </a>
            </template>
          </i18n>
          <li>{{ $t('promotion.millionDollarCashPool.eligibleDescription2') }}</li>
          <li>{{ $t('promotion.millionDollarCashPool.eligibleDescription3') }}</li>
          <li>{{ $t('promotion.millionDollarCashPool.eligibleDescription4') }}</li>
          <li>{{ $t('promotion.millionDollarCashPool.eligibleDescription5') }}</li>
        </Description>
      </el-col>
      <el-col :xs="24" class="button-box">
        <el-button class="el-button btn-blue long-button" data-testid="fund" @click="goToFund">
          {{ $t('promotion.millionDollarCashPool.fundAccount') }}
        </el-button>
      </el-col>
      <!-- t&c -->
      <el-col :xs="24" class="agreeTnc-box">
        <i18n path="promotion.cryptoCashback.tnc">
          <template v-slot:link>
            <a
              href="https://puprime.com/pdf/0528/Million_Dollars_Cash_Pool_Terms_and_Conditions.pdf"
              target="_blank"
              data-testid="tnc"
            >
              {{ $t('promotion.cryptoCashback.link') }}
            </a>
          </template>
        </i18n>
      </el-col>
    </el-row>

    <!-- table -->
    <el-row :gutter="20" class="sub-box2">
      <DescriptionTable />
    </el-row>
  </div>
</template>

<script>
import Description from '@/components/description/DescriptionV2.vue';
import DescriptionTable from '@/components/promotion/millionDollarCashPool/DescriptionTable.vue';
import blackList from '@/mixins/blackList';

export default {
  name: 'MillionDollarCashPoolRegister',
  components: {
    Description,
    DescriptionTable
  },
  mixins: [blackList],
  data() {
    return {
      puprimePageUrl: 'https://www.puprime.com/promotions/milliondollarscashpool/'
    };
  },
  methods: {
    redirectToPuprimePage() {
      window.open(this.puprimePageUrl, '_blank');
    },
    goToFund() {
      this.getDepositBlacklist();
    }
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  padding: 0px;
  margin-top: 48px;
}
@media (min-width: 550px) {
  .table-wrapper {
    margin-top: 64px;
  }
}
@media (min-width: 768px) {
  .table-wrapper {
    padding: 24px;
  }
}
@media (min-width: 1024px) {
  .table-wrapper {
    padding: 50px;
  }
}
@media (min-width: 1536px) {
  .table-wrapper {
    padding: 60px;
  }
}
</style>
