<template>
  <section id="MillionDollarCashPool">
    <!-- banner -->
    <div class="banner-container">
      <div v-if="banner" class="new-banner-image" :style="bannerInnerStyle">
        <BannerItemV2 v-bind="banner" :lang="imgLang" ltr />
      </div>
    </div>

    <!-- main -->
    <div class="content-box">
      <div class="inner">
        <div class="inner-container">
          <RegisterNow />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DescriptionTable from '@/components/promotion/millionDollarCashPool/DescriptionTable.vue';
import RegisterNow from '@/components/promotion/millionDollarCashPool/RegisterNow.vue';
import BannerItemV2 from '@/components/banner/item/v2/BannerItem.vue';
import { PROMOTION_TYPE } from '@/constants/promotion';

export default {
  name: 'MillionDollarCashPool',
  components: {
    DescriptionTable,
    RegisterNow,
    BannerItemV2
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      banner: null
    };
  },
  computed: {
    campaignID() {
      let campaign =
        this.$store.state.promotion.eligibleCampaigns.find(
          c => c.campaignType === PROMOTION_TYPE.MILLION_DOLLAR_CASH_POOL
        ) || {};
      if (!campaign.id) {
        this.$router.push('/404');
        return null;
      }
      return campaign.id;
    },
    imgLang() {
      return 'en_US';
    },
    rwdBannerBg() {
      if (this.banner?.img) {
        if (this.windowWidth <= 414) {
          return this.banner?.imgSmall;
        }
        return this.banner?.img;
      }

      return null;
    },
    bannerInnerStyle() {
      const result = {
        backgroundImage: `url(${this.rwdBannerBg})`
      };

      if (this.banner.backgroundPosition) {
        result.backgroundPosition = this.banner.backgroundPosition;
      }

      return result;
    }
  },
  mounted() {
    this.getBannerSetting();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getBannerSetting() {
      const regulatorConfig = this.$config.banner(this.regulator).filter(el => el.promotionId === this.campaignID)[0];

      this.banner = { ...regulatorConfig };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/common/promotion-base-style.scss';
</style>
