<template>
  <BlueTableWrapper>
    <div>
      <el-table :data="displayData" class="desc-table">
        <el-table-column min-width="300">
          <template slot="header">
            <div class="table-heade-cell">
              {{ $t('promotion.millionDollarCashPool.field.accumulateGross') }}
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.accumulateGross }}
          </template>
        </el-table-column>
        <el-table-column min-width="300">
          <template slot="header">
            <div class="table-heade-cell">
              {{ $t('promotion.millionDollarCashPool.field.cashbackVoucher') }}
            </div>
          </template>
          <template slot-scope="scope">{{ scope.row.cashbackVoucher }}</template>
        </el-table-column>
        <el-table-column min-width="300">
          <template slot="header">
            <div class="table-heade-cell">
              {{ $t('promotion.millionDollarCashPool.field.requiredTradingVolumn') }}
            </div>
          </template>
          <template slot-scope="scope"> {{ scope.row.requiredTradingVolumn }}</template>
        </el-table-column>
      </el-table>
    </div>
  </BlueTableWrapper>
</template>

<script>
import BlueTableWrapper from '@/components/promotion/common/BlueTableWrapper.vue';

export default {
  name: 'DescriptionTable',
  components: { BlueTableWrapper },
  data() {
    return {
      displayData: [
        {
          accumulateGross: '>=$200',
          cashbackVoucher: '$5',
          requiredTradingVolumn: '2'
        },
        {
          accumulateGross: '>=$500',
          cashbackVoucher: '$25',
          requiredTradingVolumn: '3'
        },
        {
          accumulateGross: '>=$1000',
          cashbackVoucher: '$50',
          requiredTradingVolumn: '4'
        },
        {
          accumulateGross: '>=$2000',
          cashbackVoucher: '$100',
          requiredTradingVolumn: '6'
        },
        {
          accumulateGross: '>=$5000',
          cashbackVoucher: '$250',
          requiredTradingVolumn: '20'
        },
        {
          accumulateGross: '>=$10000',
          cashbackVoucher: '$500',
          requiredTradingVolumn: '30'
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/promotion/blueHeadTable.scss';

/deep/ .el-table.head-gutter td {
  border-bottom: 1px solid #ebeef5;
}

.el-table.head-gutter /deep/ th .cell {
  padding: 14px 12px;
}

.table-heade-cell {
  min-height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
